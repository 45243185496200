export interface SelectedUserInterface {
  role: string;
  email?: string;
  userId?: string;
}

export interface InvitePayloadInterface {
  canCopy: boolean;
  canGrantAccess: boolean;
  message: string;
}

interface PublishAnalytic {
  changed: number;
  unchanged: number;
  never: number;
}

interface StateAnalytic {
  draft: number;
  ready: number;
  current: number;
}

export interface MapCountAnalytic {
  counts: {
    [key: string]: PublishAnalytic | StateAnalytic;
  };
}

export enum MAP_TYPE_ENUM {
  ARCHITECTURE = 'architecture',
  UPN = 'upn',
  BUSINESS_PROCESS = 'business process',
  ALL = 'all'
}

export const MAP_TYPES = {
  UPN: 'upn',
  ARCHITECTURE: 'architecture'
} as const;

export type MapType = (typeof MAP_TYPES)[keyof typeof MAP_TYPES];

export interface MapGenerationPreset {
  name: string;
  description?: string;
  guidance?: string;
}

export interface MapGenerationPreset {
  name: string;
  description?: string;
  guidance?: string;
}
